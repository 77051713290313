<template>
  <WsMain class="shop-order-index">
    <WsCrud
      ref="Crud"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="false"
      :deletable="false"
      :inRowBtnDelete="false"
      :inRowBtnUpdate="false"
      :inRowBtnRead="false"
      :showExpand="false"
      :expandable="false"
      :filterUniqueSection="true"
      :creatable="false"
      :customTableActions="customTableActions"
      :filterSelects="filterSelects"
      selectable
      :selected.sync="selected"
      :titleBarCustomBtns="_titleBarCustomBtns"
      :titleCustomDropdownMenu="_titleCustomDropdownMenu"
      :itemsPerPage="100"
      :plusOrderItems="plusOrderItems"
      @return-part="$_onReturnPart($event)"
      @return-all="$_onReturnAll($event)"
      @update-invoice-status="$_onUpdateInvoiceStatus($event)"
      @customer-service-remarked="$_onCustomerServiceRemark($event)"
      @read="$_onRead($event)"
      @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
      @title-custom-dropdown-menu-click="$_titleBarCustomDropdownClick($event)"
      @re-invoice="$_onReInvoice($event)"
      @update:params="$_onUpdateParams($event)"
      v-model="modelDatas"
    >

    </WsCrud>
    <WsDialogShopReturnPart
      ref="WsDialogShopReturnPart"
      @complete="$_onCompleteShopReturnPart($event)"
    ></WsDialogShopReturnPart>
    <WsDialogShopReturnAll
      ref="WsDialogShopReturnAll"
      @complete="$_onCompleteShopReturnAll($event)"
    ></WsDialogShopReturnAll>
    <WsDialogShopUpdateInvoiceStatus
      ref="WsDialogShopUpdateInvoiceStatus"
      @complete="$_onUpdateInvoiceStatusComplete($event)"
    ></WsDialogShopUpdateInvoiceStatus>
    <WsDialogShopCustomerServiceRemark ref="WsDialogShopCustomerServiceRemark"></WsDialogShopCustomerServiceRemark>
    <WsDialogShopOrderRead
      ref="WsDialogShopOrderRead"
      @return-part="$_onReadReturnPart($event)"
      @return-all="$_onReadReturnAll($event)"
      @complete-return-cancel="$_onCompleteReturnCancel($event)"
      @update="$_updateModelData($event)"
    ></WsDialogShopOrderRead>
    <WsPopupShopProductExport
      ref="WsPopupShopProductExport"
      :params="_shopProductExportParams"
    ></WsPopupShopProductExport>
    <WsPopupAlert
      ref="WsPopupAlert"
      @submit="$_onPopupAlertSubmit($event)"
    ></WsPopupAlert>
  </WsMain>
</template>

<script>
import S_ShopOrder from "@/__vue2stone_cms/service/api/v1/shop_order";
export default {
  methods: {
    $_onUpdateInvoiceStatus($event) {
      this.$refs.WsDialogShopUpdateInvoiceStatus.open($event.item.id)
    },
    $_onUpdateInvoiceStatusComplete($event) {
      this.$_updateModelData($event);
    },
    $_onReInvoice($event) {
      this.$refs.WsPopupAlert.open(
        {
          id: "re-invoice",
          value: $event,
        },
        this.$t("重新開立發票"),
        this.$t("是否重新開立發票？")
      );
    },
    async $_onPopupAlertSubmit($event) {
      this.$refs.WsPopupAlert.loadingStart();
      let key = "";
      if ($event.id == "change_order_status") {
        key = "status";
        const _ids = this._ids.filter((item, index) => {
          return this.$_changeOrderStatusCheck(
            this.selected[index][key],
            $event.value
          );
        });
        const res = await S_ShopOrder.batch_update(_ids, {
          [key]: $event.value,
        });
        this.$_updateModelDatas(res);
      } else if ($event.id == "change_ship_status") {
        key = "ship_status";
        const _ids = this._ids.filter((item, index) => {
          return this.$_changeShipStatusCheck(
            this.selected[index][key],
            $event.value
          );
        });
        const res = await S_ShopOrder.batch_update(_ids, {
          [key]: $event.value,
        });
        if ($event.value === "shipped" && _ids.length) {
          this.$_onPopupAlertSubmit({
            id: "change_order_status",
            value: "complete",
          });
        }
        this.$_updateModelDatas(res);
      } else if ($event.id == "re-invoice") {
        await S_ShopOrder.re_create_invoice($event.value.item.id);
      } else if ($event.id === "print") {
        const _data = {
          ...$event,
          id: "change_ship_status",
        };
        this.$_onPopupAlertSubmit(_data);
        const ids = this.selected.map((item) => item.id);
        window.open(`/print/shop_order_pick?ids=${ids.join()}`, "_blank");
      }
      this.$refs.WsPopupAlert.close();
    },
    async $_exportShopOrder() {
      S_ShopOrder.export_excel({
        selected: this.selected,
        params: this.params,
      });
    },
    async $_exportPdf() {
      S_ShopOrder.export_prd({
        selected: this.selected,
      });
    },
    $_onUpdateParams($event) {
      this.params = $event;
    },
    $_titleBarCustomBtnClick($event) {
      if ($event == "export-shop-product-excel") {
        this.$refs.WsPopupShopProductExport.open();
      } else if ($event == "export-excel") {
        this.$_exportShopOrder();
      } else if ($event === "print") {
        const _data = {
          id: "print",
          value: "collected",
        };
        const title = this.$t("是否確定列印？");
        const description = this.$t(
          "列印撿貨單時，會自動將訂單出貨狀態由「待出貨」改為「準備出貨」，已為準備出貨的訂單則不受影響。"
        );
        this.$refs.WsPopupAlert.open(_data, title, description);
      } else if ($event === "status-shipped") {
        const _unfulfilled = this.selected.filter(
          (item) => item.ship_status === "unfulfilled"
        ).length;
        const _collected = this.selected.filter(
          (item) => item.ship_status === "collected"
        ).length;
        const _data = {
          id: "change_ship_status",
          value: "shipped",
        };
        const description = this.$t(
          "{unfulfilled} 筆需先從待出貨轉換成準備出貨，將會成功轉換 {collected} 筆",
          { unfulfilled: _unfulfilled, collected: _collected }
        );
        const title = this.$t("確定要更改出貨狀態嗎？");
        this.$refs.WsPopupAlert.open(_data, title, description);
      }
    },
    $_titleBarCustomDropdownClick($event) {
      let title;
      let description = this.$t("是否確定更改？");
      if ($event.id == "change_order_status") {
        title = this.$t("確定要更改訂單狀態嗎？");
        let changeStatusCount = 0;
        this.selected.forEach((item) => {
          if (this.$_changeOrderStatusCheck(item.status, $event.value)) {
            changeStatusCount++;
          }
        });
        const targetStatus = $event.value
          ? this.$store.state.stone_model.shop_order.fields.status.items.find(
              (item) => item.value === $event.value
            ).text
          : "";
        description = this.$t(
          `共有 ${changeStatusCount} 筆訂單將會轉為 "${targetStatus}" 狀態，${
            this.selected.length - changeStatusCount
          } 筆訂單因不符條件將不變更狀態`
        );
      } else if ($event.id === "change_ship_status") {
        title = this.$t("確定要更改出貨狀態嗎？");
      }
      this.$refs.WsPopupAlert.open($event, title, description);
    },
    $_updateModelDatas(modelDatas) {
      modelDatas.forEach((modelData) => {
        this.$_updateModelData(modelData);
      });
    },
    $_updateModelData(modelData) {
      const _index = this.modelDatas.findIndex((e) => {
        return e.id == modelData.id;
      });
      this.modelDatas[_index] = {
        ...this.modelDatas[_index],
        ...modelData,
      };
      this.modelDatas = [...this.modelDatas];
      this.$_updateModelDataInSelected(modelData);
    },
    $_updateModelDataInSelected(modelData) {
      if (!this.selected) {
        return;
      }
      const _index = this.selected.findIndex((e) => {
        return e.id == modelData.id;
      });
      if (!_index <= 0) {
        return;
      }
      this.selected[_index] = {
        ...this.selected[_index],
        ...modelData,
      };
    },
    $_onRead($event) {
      this.$refs.WsDialogShopOrderRead.open($event.item.id);
    },
    $_onCompleteShopReturnPart($event) {
      this.$_updateModelData($event);
    },
    $_onCompleteShopReturnAll($event) {
      this.$_updateModelData($event);
    },
    $_onCompleteReturnCancel($event) {
      this.$_updateModelData($event);
    },
    $_onReadReturnPart($event) {
      this.$refs.WsDialogShopReturnPart.open($event);
    },
    $_onReturnPart($event) {
      this.$refs.WsDialogShopReturnPart.open($event.item.id);
    },
    $_onReadReturnAll($event) {
      this.$refs.WsDialogShopReturnAll.open($event);
    },
    $_onReturnAll($event) {
      this.$refs.WsDialogShopReturnAll.open($event.item.id);
    },
    $_onCustomerServiceRemark($event) {
      this.$refs.WsDialogShopCustomerServiceRemark.open($event.item.id);
    },
    $_changeOrderStatusCheck(currValue, targetValue) {
      // status
      if (currValue === targetValue) return false;
      if (currValue === "established") {
        if (
          targetValue === "return-part-apply" ||
          targetValue === "cancel" ||
          targetValue === "complete"
        ) {
          return true;
        }
      } else if (currValue === "not-established") {
        if (targetValue === "established") return true;
      } else if (currValue === "return-part-apply") {
        if (
          targetValue === "return-part-complete" ||
          targetValue === "established"
        )
          return true;
      } else if (currValue === "cancel") {
        if (targetValue === "cancel-complete" || targetValue === "established")
          return true;
      } else if (currValue === "return-part-complete") {
        if (targetValue === "return-part-apply") return true;
      } else if (currValue === "complete") {
        if (targetValue === "return-part-apply" || targetValue === "cancel")
          return true;
      }
      return false;
    },
    $_changeShipStatusCheck(currValue, targetValue) {
      // ship_status
      if (currValue === targetValue) return false;
      if (targetValue === "unfulfilled") {
        if (currValue === "shipped") return true;
      } else if (targetValue === "collected") {
        if (currValue === "unfulfilled" || currValue === "shipped") return true;
      } else if (targetValue === "shipped") {
        if (currValue === "collected") return true;
      }
      return false;
    },
  },
  computed: {
    _shopProductExportParams() {
      return {
        shop_order_request: {
          ...this.params,
          ids: this._ids.join(),
        },
      };
    },
    _ids() {
      return this.selected ? this.selected.map((item) => item.id) : [];
    },
    _titleBarCustomBtns() {
      let _titleBarCustomBtns = [];
      _titleBarCustomBtns.push({
        label: this.selected.length
          ? this.$t("匯出所選訂單")
          : this.$t("匯出訂單"),
        event: "export-excel",
        icon: {
          left: true,
          name: "icon-ws-outline-cloud-download",
        },
      });
      if (this.params.created_at) {
        _titleBarCustomBtns.push({
          label: this.$t("匯出商品銷量"),
          event: "export-shop-product-excel",
          icon: {
            left: true,
            name: "icon-ws-outline-cloud-download",
          },
        });
      }
      if (this.selected.length) {
        _titleBarCustomBtns.push(
          {
            label: this.$t("列印揀貨單"),
            event: "print",
            icon: {
              left: true,
              name: "icon-md-print",
            },
          },
          {
            label: this.$t("已出貨"),
            event: "status-shipped",
          }
        );
      }
      return _titleBarCustomBtns;
    },
    _titleCustomDropdownMenu() {
      let _titleCustomDropdownMenu = [];
      if (this.selected.length) {
        _titleCustomDropdownMenu.push(
          {
            id: "change_order_status",
            items: [
              {
                text: this.$t("部分退訂完成"),
                value: "return-part-complete",
              },
              {
                text: this.$t("全部退訂完成"),
                value: "cancel-complete",
              },
              {
                text: this.$t("訂單完成"),
                value: "complete",
              },
            ],
            placeholder: this.$t("更改訂單狀態"),
          },
          {
            id: "change_ship_status",
            items: [
              {
                text: this.$t("待出貨"),
                value: "unfulfilled",
              },
              {
                text: this.$t("準備出貨"),
                value: "collected",
              },
              // {
              //   text: this.$t("已出貨"),
              //   value: "shipped",
              // },
            ],
            placeholder: this.$t("更改出貨狀態"),
          }
        );
      }
      return _titleCustomDropdownMenu;
    },
  },
  data() {
    return {
      params: {},
      modelDatas: [],
      selected: [],
      ...this.$store.state.stone_model.shop_order,
      WsPopupAlertMessage: "",
      showFields: [
        "need_handle_alert",
        "ship_status",
        "status",
        "pay_status",
        "invoice_status",
        "order_info",
        "orderer_info",
        "shop_date_time",
        "receiver_info",
        "order_price_order_way",
        "receive_way_receive_remark",
      ],
      customTableActions: [
        {
          icon: "icon-ws-outline-eye-open",
          emit: "read",
          tooltip: this.$t("閱讀"),
        },
        {
          icon: "icon-ws-outline-edit-pencil",
          emit: "customer-service-remarked",
          tooltip: this.$t("編輯客服備註"),
        },
        {
          icon: "icon-ll-nav-audit-filled",
          emit: "update-invoice-status",
          tooltip: this.$t("編輯發票狀態"),
        },
        {
          icon: "icon-md-subdirectory-arrow-left",
          emit: "return-part",
          tooltip: this.$t("部分退訂"),
        },
        {
          icon: "icon-md-call-merge",
          emit: "return-all",
          tooltip: this.$t("全部退訂"),
        },
        {
          icon: "icon-ws-officialweb-send",
          emit: "re-invoice",
          tooltip: this.$t("重新開立發票"),
          displayCheck: function (item) {
            return item.invoice_status !== "fail" ? false : true;
          },
        },
      ],
      filterSelects: {
        pay_status: {
          type: "multi-select",
          placeholder: "篩選付款狀態",
          selectAll: true,
          items: [
            {
              text: this.$t("未付款") + "-" + this.$t("已取消"),
              value: "not-paid",
            },
            {
              text: this.$t("未付款") + "-" + this.$t("待付款"),
              value: "waiting",
            },
            {
              text: this.$t("已付款"),
              value: "paid",
            },
          ],
          col: 2,
        },
        status: {
          type: "multi-select",
          placeholder: this.$t("篩選訂單狀態"),
          selectAll: true,
          items: [
            {
              text: this.$t("成立"),
              value: "established",
            },
            {
              text: this.$t("未成立"),
              value: "not-established",
            },
            {
              text: this.$t("申請部分退訂"),
              value: "return-part-apply",
            },
            {
              text: this.$t("申請全部退訂"),
              value: "cancel",
            },
            {
              text: this.$t("部分退訂完成"),
              value: "return-part-complete",
            },
            {
              text: this.$t("全部退訂完成"),
              value: "cancel-complete",
            },
            {
              text: this.$t("訂單完成"),
              value: "complete",
            },
          ],
          col: 2,
        },
        ship_status: {
          type: "multi-select",
          placeholder: this.$t("篩選出貨狀態"),
          selectAll: true,
          items: [
            {
              text: this.$t("待出貨"),
              value: "unfulfilled",
            },
            {
              text: this.$t("準備出貨"),
              value: "collected",
            },
            {
              text: this.$t("已出貨"),
              value: "shipped",
            },
          ],
          col: 2,
        },
        invoice_status: {
          type: "multi-select",
          placeholder: this.$t("篩選發票狀態"),
          items: [
            {
              text: this.$t("開立成功"),
              value: "done",
            },
            {
              text: this.$t("開立失敗"),
              value: "fail",
            },
            {
              text: this.$t("手動開立成功"),
              value: "done-hand",
            },
          ],
          selectAll: true,
          col: 2,
        },
        remark_status: {
          type: "select",
          placeholder: this.$t("篩選訂單是否異常"),
          items: [
            {
              text: this.$t("沒有異常"),
              value: "",
            },
            {
              text: this.$t("問題待處理"),
              value: "waiting-handle",
            },
            {
              text: this.$t("有客服備註"),
              value: "customer-service-remarked",
            },
          ],
          col: 3,
        },
        area_area_section: {
          type: "belongs-to-many-multi-layers",
          placeholder: this.$t("篩選地區"),
          col: 2,
          layerFields: [
            {
              modelName: "area",
              stateKey: "area",
              modelKey: "area",
            },
            {
              modelName: "area_section",
              stateKey: "area_section",
              modelKey: "area_sections",
            },
          ],
        },
        shop_ship_time_setting: {
          type: "belongsToMany",
          placeholder: this.$t("篩選配送時段"),
          col: 2,
          textKey: "start_time",
          getText: function (value) {
            return `${
              this.$moment(value.start_time).format("HH:mm:ss") || ""
            }-${this.$moment(value.end_time).format("HH:mm:ss") || ""}`;
          },
          modelName: "shop_ship_time_setting",
        },
        created_at: {
          type: "date-range",
          placeholder: this.$t("篩選訂購日期"),
          col: 2,
        },
        ship_date: {
          type: "date-range",
          placeholder: this.$t("篩選出貨時間"),
          col: 2,
        },
        return_at: {
          type: "date-range",
          placeholder: this.$t("篩選退貨時間"),
          col: 2,
        },
        source: {
          type: "multi-select",
          placeholder: this.$t("篩選訂單來源"),
          items: [
            {
              text: "PC",
              value: "web-pc",
            },
            {
              text: "APP",
              value: "app",
            },
            {
              text: "WEB",
              value: "web-mobile",
            },
          ],
          col: 2,
        },
      },
      plusOrderItems: [
        {
          value: "first_receive_address",
          text: this.$t("依收件者地址"),
          order_by: "receive_address",
          order_way: "asc",
        },
      ],
    };
  },
};
</script>

<style lang="scss" >
.shop-order-index {
  .ws-crud {
    .ws-crud-table {
      .ws-crud-table__table-container {
        height: calc(100vh - 380px);
      }
    }
  }
}
</style>